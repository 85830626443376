import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { RoleService } from 'src/app/services/role.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private roleService: RoleService
  ) {
    super(router, keycloak);
  }
  
  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    const authorizedRoles = route.data.authorizedRoles;
    const currentRole = await this.roleService.getCurrentRole();

    if (this.authenticated && this.roleService.hasRole(authorizedRoles, currentRole)) {
      return true;
    }
    else {
      return this.router.parseUrl('/access-denied');
    }
  }
}