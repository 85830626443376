import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { UserService } from 'src/app/services/user.service';

@Injectable({
    providedIn: 'root',
  })
export class RoleService {

    public userProfile: KeycloakProfile | null = null;

    constructor(private readonly keycloak: KeycloakService, public userService: UserService) {}

   findRole(strings: string[]): string | null {
    for (const str of strings) {
      if (str.includes('referent')) {
        return 'referent';
      } else if (str.includes('student')) {
        return 'student';
      } else if (str.includes('admin')) {
        return 'admin';
      }
    }
    return null;
  }

    async getCurrentRole(): Promise<string> {
        this.userProfile = await this.keycloak.loadUserProfile();
        return this.findRole(this.keycloak.getKeycloakInstance().tokenParsed.realm_access.roles)
    }

    hasRole(authorizedRoles: string[], currentRole): boolean {
        if (!authorizedRoles) {
            return true;
        }
        if (!this.userProfile) {
          return false;
        }
        return authorizedRoles.includes(currentRole);
    }
}
